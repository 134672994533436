import AuthRepository from './core/auth-repository';
import RodoRepository from "./core/rodo-repository";
import UserAccountRepository from './core/user-account-repository';
import SubaccountRepository from './subaccount/subaccount-repository';
import WalletRepository from "./subaccount/wallet-repository";
import MessageRepository from "./message/message-repository";

const repositories = {
    'AuthRepository': AuthRepository,
    'UserAccountRepository': UserAccountRepository,
    'SubaccountRepository': SubaccountRepository,
    'WalletRepository': WalletRepository,
    'RodoRepository': RodoRepository,
    'MessageRepository': MessageRepository
};

export default {
    get: name => repositories[name]
};
