import Client from '../axios-client';

const resource = 'subaccount/guardianapp/subaccount';

export default {
    getQuickSearch() {
        return Client.get(`${resource}/quickSearch`);
    },
    getExcel: function () {
        return Client.get(`${resource}/excel`,
            {
                responseType: 'blob',
                headers: { 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            }
        )},
    getItem(id) {
        return Client.get(`${resource}/${id}`);
    }
};
