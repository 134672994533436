import {AuthJwtService} from '../../app/service/auth/auth-jwt-service';
import router from '@/router/routes';

const user = JSON.parse(localStorage.getItem('user'));
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

const actions = {
    // Logs in the user.
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });

       return AuthJwtService.login(username, password)
            .then(
                user => {
                    let localUser = JSON.parse(localStorage.getItem('user'));
                    const lastItem = localUser.accessToSubaccount[Object.keys(localUser.accessToSubaccount)[0]];
                    const checkRoleGuardian = localUser.roles.includes('ROLE_GUARDIAN');

                    if (checkRoleGuardian === false || lastItem === undefined) {
                        AuthJwtService.logout();
                        dispatch('alert/error', 'Nie posiadasz uprawnień do tej aplikacji lub brak przypisanych podopiecznych. Skontaktuj się z fundacją.', { root: true });
                        return;
                    }

                    commit('loginSuccess', user);
                    router.push('/subaccount/profile/' + lastItem.subaccountUuid);
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        AuthJwtService.logout();
        commit('logout');
    }
};

const getters = {
    getUser(state) {
      return state.user;
    }
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    }
};

export const auth = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
