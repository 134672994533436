import Client from '../axios-client';

const resource = '/subaccount/guardianapp/wallet';

export default {
    getGridOperations(parameters) {
        return Client.get(`${resource}/operations/grid` + parameters);
    },
    getGridOperationsExcel: function (parameters) {
        return Client.get(`${resource}/operations/excel` + parameters,
            {
                responseType: 'blob',
                headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            }
        )
    },
};
