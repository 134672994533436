import Client from '../axios-client';
import {SubaccountContextService} from '@/app/service/subaccount-context/subaccount-context-service';

const resource = '/message/preparation/guardianapp/message';

export default {
  createDraft(payload) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.post(`${resource}`, payload);
  },
  createDraftMessageFromParentMessage(payload) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.post(`${resource}/from-parent`, payload);
  },
  changeDraft(id, payload) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.put(`${resource}/${id}`, payload);
  },
  removeDraft(id, version) {
    return Client.delete(`${resource}/${id}?version=${version}&subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  sendMessage(id, payload) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.put(`${resource}/${id}/send`, payload);
  },
  getGrid(parameters, payload) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.post(`${resource}/grid` + parameters, payload);
  },
  getItem(id) {
    return Client.get(`${resource}/${id}?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  countUnread() {
    return Client.get(`${resource}/count-unread?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  findAllUnreadMessagesIds() {
    return Client.get(`${resource}/list-unread?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  getUrlForAddAttachmentToDraftMessage: function (id) {
    return Client.defaults.baseURL + `${resource}/${id}/attachment?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`;
  },
  addAttachmentToDraftMessage: function (payload, id) {
    payload.subaccountContextId = SubaccountContextService.getSubaccountContextId();
    return Client.post(
      `${resource}/${id}/attachment`,
      payload,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }
    );
  },
  removeAttachmentFromDraftMessage(id, attachment, version) {
    return Client.delete(`${resource}/${id}/attachment/${attachment}?version=${version}&subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  getAttachment(messageId, attachmentId) {
    return Client.get(`${resource}/${messageId}/attachment/${attachmentId}/download?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`,
      {
        responseType: 'blob',
      }
    )
  },
  getReplyAttachment(messageId, attachmentId, replyId) {
    return Client.get(`${resource}/${messageId}/reply/${replyId}/attachment/${attachmentId}/download?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`,
        {
          responseType: 'blob',
        }
    )
  },
  sendReply(id, payload) {
    payload.append('subaccountContextId', SubaccountContextService.getSubaccountContextId());
    return Client.post(
        `${resource}/${id}/reply`,
        payload,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
    );
  },
  markAsRead(messageId) {
    return Client.put(`${resource}/${messageId}/mark-as-read?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
  markAsUnread(messageId) {
    return Client.put(`${resource}/${messageId}/mark-as-unread?subaccountContextId=${SubaccountContextService.getSubaccountContextId()}`);
  },
};
