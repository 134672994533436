import Client from '../axios-client';
import ClientBaseDomain from '../axios-client-base-domain'

const resource = '/core/userAccount';

export default {
    getMyAccount() {
        return Client.get(`${resource}/myAccount`);
    },
    changePassword(payload) {
        return Client.put(`${resource}/changePassword`, payload);
    },
    resetPassword(payload) {
        return ClientBaseDomain.put(`/resetPassword/true`, payload);
    },
    changePasswordWithToken(payload, token) {
        return ClientBaseDomain.put(`/changePassword/${token}`, payload);
    }
};
