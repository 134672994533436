import Vue from 'vue';
import Router from 'vue-router';
/* eslint-disable no-unused-vars */
import appConfig from '../../src/app.config'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/home'),
      meta: {
        authRequired: true,
        title: 'Dashboard' + ' | ' + appConfig.title
      }
    },
    {
      path: '/login',
      name: 'Logowanie',
      component: () => import('./views/login'),
      meta: {
        authRequired: false,
        title: 'Login' + ' | ' + appConfig.title,
      },
    },
    {
      path: '/rodo',
      name: 'Akceptacja RODO',
      component: () => import('./views/account/rodo'),
      meta: {
        authRequired: true,
        title: 'Akceptacja RODO' + ' | ' + appConfig.title,
      },
    },
    {
      path: '/logout',
      name: 'Wyloguj',
      component: () => import('./views/logout'),
      meta: {
        authRequired: false,
        title: 'Wyloguj' + ' | ' + appConfig.title,
      },
    },
    {
      path: '/change-password/:token',
      name: 'Zmiana hasła',
      component: () => import('./views/change-password'),
      meta: {
        authRequired: false,
        title: 'Zmiana hasła' + ' | ' + appConfig.title,
      },
    },
    {
      path: '/forgot-password',
      name: 'Resetowanie hasła',
      component: () => import('./views/forgot-password'),
      meta: {
        authRequired: false,
        title: 'Resetowanie hasła' + ' | ' + appConfig.title,
      },
    },
    {
      path: '/404',
      name: '404',
      meta: {
        authRequired: false,
        title: '404' + ' | ' + appConfig.title },
      component: require('./views/utility/404').default,
    },
    {
      path: '/500',
      name: '500',
      meta: {
        authRequired: false,
        title: '404' + ' | ' + appConfig.title },
      component: require('./views/utility/500').default,
    },
    {
      path: '/subaccount/profile/:id',
      name: 'Profil podopiecznego',
      component: () => import('./views/subaccount/subaccount-profile'),
      meta: {
        authRequired: true,
        title: 'Profil podopiecznego' + ' | ' + appConfig.title
      }
    },
    {
      path: '/account/profile/:id',
      name: 'Profil pracownika',
      component: () => import('./views/account/account-profile'),
      meta: {
        authRequired: true,
        title: 'Profil pracownika' + ' | ' + appConfig.title
      }
    },
    {
      path: '/message/list/:messageCategory',
      name: 'Lista wiadomości',
      component: () => import('./views/message/message-list'),
      meta: {
        authRequired: true,
        title: 'Wiadomości' + ' | ' + appConfig.title
      }
    },
    {
      path: '/message/:messageCategory/:id',
      name: 'Szczegóły wiadomości',
      component: () => import('./views/message/message-details'),
      meta: {
        authRequired: true,
        title: 'Szczegóły wiadomości' + ' | ' + appConfig.title
      }
    },
    {
      path: '/message/:messageCategory/:id/edit',
      name: 'Edycja wiadomości',
      component: () => import('./views/message/message-edit'),
      meta: {
        authRequired: true,
        title: 'Edycja wiadomości' + ' | ' + appConfig.title
      }
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    {
      path: '*',
      redirect: '404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.meta.authRequired;
  const token = localStorage.getItem('token');
  const userData = JSON.parse(localStorage.getItem('user'));

  if (token) {
    const tokenData = JSON.parse(atob(token.split('.')[1]));

    if (((Date.now() / 1000)) >= tokenData.exp) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }

  if (authRequired && !token) {
    return next('/login');
  }

  if (userData && userData.rodoAccepted === false && (to.name === 'Home' || to.name === 'Profil podopiecznego' || to.name === 'Profil pracownika'))
  {
    return next('/rodo');
  }

  document.title = to.meta.title;
  next()
});

export default router;
