import AxiosClient from "../../repository/axios-client";
import Repository from "../../repository/repository-factory";

const AuthRepository = Repository.get("AuthRepository");
const UserAccountRepository = Repository.get("UserAccountRepository");

export const AuthJwtService = {
    login,
    logout,
    isGranted
};

function login(_username, _password) {
    const username = _username.trim();
    const password = _password.trim();
    return AuthRepository.login(JSON.stringify({ username, password }))
    .then(response => {
        if (response.data.token) {
            AxiosClient.defaults.headers.common['Authorization'] = "Bearer " + response.data.token;
            localStorage.setItem('token', JSON.stringify(response.data));
            return UserAccountRepository.getMyAccount()
        }
    })
    .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));

        let localUser = JSON.parse(localStorage.getItem('user'));

        const lastItem = localUser.accessToSubaccount[Object.keys(localUser.accessToSubaccount)[0]].subaccountUuid;

        if (localStorage.getItem('subaccountContextId') === null) {
            localStorage.setItem('subaccountContextId', lastItem);
        }

        return response.data;
    })
    .catch((error) => {
        const errorOut = (error && error.response.data.message) || error;
        logout();
        return Promise.reject(errorOut);
    });
}

function isGranted(role) {
    const userData = JSON.parse(localStorage.getItem('user'));
    const roles = userData.roles;
    let result = roles.indexOf(role);

    if (result < 0) {
        return false;
    }

    return true;
}

function logout() {
    // remove user from local storage to log user out
    AxiosClient.defaults.headers.common['Authorization'] = "";
    localStorage.removeItem('token');
    localStorage.removeItem('user');
}
