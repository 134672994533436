import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/routes'
import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from './state/store/index.js'

import App from './App.vue'

Vue.use(VueRouter);
Vue.use(vco);

if(process.env.NODE_ENV !== 'development') {
  Vue.config.productionTip = false;
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require('vue-chartist'));
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
});
Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
