import axios from "axios";

const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}`;

if (localStorage.getItem('token') !== null) {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = "Bearer " + JSON.parse(token).token;
}

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

export default instance;
