export const SubaccountContextService = {
    addSubaccountContextId,
    getSubaccountContextId,
    removeSubaccountContextId
};

function addSubaccountContextId(subaccountContextId) {
    localStorage.setItem('subaccountContextId', subaccountContextId);
}

function getSubaccountContextId() {
    return localStorage.getItem('subaccountContextId');
}

function removeSubaccountContextId() {
    localStorage.removeItem('subaccountContextId');
}
